import React from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import ImgFull from "../components/ImgFull";

const SecondaryHeader = ({ title, image }) => (
  <div className="secondary-header">
    {image ? <ImgFull image={image} /> : ""}

    <div className="secondary-header-title">
      <br />
      <h1>{title}</h1>
    </div>
    <div className="transparency"></div>
  </div>
);

export const BlogPostTemplate = ({
  content,
  contentComponent,
  title,
  featuredimage,
  helmet
}) => {
  const PostContent = contentComponent || Content;

  return (
    <section className="section">
      {helmet || ""}
      <SecondaryHeader title={title} image={featuredimage} />
      <div className="navbar-margin content">
        {/* <h1>{title}</h1> */}
        <PostContent className="" content={content} />
      </div>
    </section>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,

  title: PropTypes.string,
  helmet: PropTypes.object
};

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        helmet={
          <Helmet titleTemplate="%s">
            <title>{`${post.frontmatter.title}`}</title>
          </Helmet>
        }
        title={post.frontmatter.title}
        featuredimage={post.frontmatter.featuredimage}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        order
        title
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 70) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
